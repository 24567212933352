import React, { useState, useEffect } from "react";
import {
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaFacebook,
} from "react-icons/fa";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import { FaShareNodes } from "react-icons/fa6";
const images = [bg1, bg2, bg3];

const Contact = () => {
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "CCcreations",
          text: "Check out CCcreations for all your construction needs!",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="text-center jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#2A2B2A] bg-opacity-50 flex items-center justify-center flex-col text-center">
          <h1 className="text-[#e7a034] text-4xl font-bold mb-4 mt-12">
            Contact Us
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#2A2B2A] text-[#e7a034] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>(206) 742-2047</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl text-center underline underline-offset-2 font-bold mb-4">
          Contact Us:
        </h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaPhoneAlt
              className="text-[#2A2B2A]"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="tel:+12067422047"
              className="bg-[#e7a034] text-[#2A2B2A] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              206-742-2047
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaShareNodes
              className="text-[#2A2B2A]"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="#"
              onClick={handleShare}
              className="bg-[#e7a034] text-[#2A2B2A] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              Share this website
            </a>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaMapMarkerAlt
              className="text-[#2A2B2A]"
              style={{ verticalAlign: "middle" }}
            />
            <p className="text-lg">6225 67th Ave NE, Marysville, WA</p>
          </motion.div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <FaEnvelope
              className="text-[#2A2B2A]"
              style={{ verticalAlign: "middle" }}
            />
            <a
              href="mailto:cccreations206@gmail.com"
              className="bg-[#e7a034] text-[#2A2B2A] px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: "500px" }}
            >
              cccreations206@gmail.com
            </a>
          </motion.div>
        </div>
        <div className="flex justify-center space-x-4">
          <a
            href="https://www.facebook.com/CCcreationsPNW"
            target="_blank"
            rel="noreferrer"
            className="text-[#2A2B2A] hover:text-[#e7a034]"
          >
            <FaFacebook size={40} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
